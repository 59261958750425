import GhostContentAPI  from '@tryghost/content-api';
import SearchinGhost from 'searchinghost';

const ghostSettings = {
    url: "https://echodxai.ghost.io",
    key: "521515b79a7a4c68e94e8b7e06"
}

export const searchinGhost = new SearchinGhost({
    key: ghostSettings.key,
    url: ghostSettings.url,
    inputId: null,
    outputId: null,
    limit: 0,
    postsFormats: ['plaintext'],
    indexedFields: ['title', 'slug', 'excerpt', 'plaintext'],
    version: 'v3'
});

export const api = new GhostContentAPI({
  key: ghostSettings.key,
  url: ghostSettings.url,
  version: 'v3'
});